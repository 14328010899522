import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";  // Importar Firebase Storage

const firebaseConfig = {
    apiKey: "AIzaSyAWRN3U2QKT4k8HHJx19RwkwSSspuiRdO8",
    authDomain: "app-sorteos-fbe45.firebaseapp.com",
    projectId: "app-sorteos-fbe45",
    storageBucket: "app-sorteos-fbe45.appspot.com",
    messagingSenderId: "800360803833",
    appId: "1:800360803833:web:59516870a43f2be1972128",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);  // Inicializar Firebase Storage

export { app, db, auth, storage };  // Exportar Firebase Storage




