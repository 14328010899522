// ThankYouPage.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import './ThankYouPage.css'; // Asegúrate de crear un archivo CSS para los estilos

const ThankYouPage = () => {
  const location = useLocation();
  const ticketData = location.state?.ticket;

  if (!ticketData) {
    return <p>Error: No se encontraron datos del ticket.</p>;
  }

  return (
    <div className="thank-you-page">
      <div className="ticket">
        <div className="ticket-header">
          <div className="ticket-barcode">██████ ██████ ██████</div>
          <h1>LOTTERY</h1>
          <p>{ticketData.id}</p>
        </div>
        <div className="ticket-body">
          <div className="ticket-numbers">
            {ticketData.boletos.map((numero, index) => (
              <span key={index} className="ticket-number">
                {numero}
              </span>
            ))}
          </div>
          <div className="ticket-info">
            <p><strong>Nombre:</strong> {ticketData.nombre}</p>
            <p><strong>Apellido:</strong> {ticketData.apellido}</p>
            <p><strong>Teléfono:</strong> {ticketData.telefono}</p>
          </div>
          <div className="ticket-footer">
            <p>Price: ${ticketData.boletos.length * 10}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
