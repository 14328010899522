import React, { useState, useEffect } from 'react';
import { collection, doc, onSnapshot, deleteDoc, getDocs, writeBatch } from 'firebase/firestore';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { db } from '../firebase';
import CrearSorteoModal from './CrearSorteoModal';
import { toast } from 'react-toastify';

const SorteosPanel = () => {
  const [sorteos, setSorteos] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/');
      }
    });

    const unsubscribeSorteos = onSnapshot(collection(db, 'sorteos'), (snapshot) => {
      const sorteosList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSorteos(sorteosList);
    });

    return () => {
      unsubscribeAuth();
      unsubscribeSorteos();
    };
  }, [auth, navigate]);

  const handleEliminarSorteo = async (sorteoId) => {
    try {
      const boletosCollection = collection(db, `sorteos/${sorteoId}/boletos`);
      const boletosSnapshot = await getDocs(boletosCollection);
      const batch = writeBatch(db);

      boletosSnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      await deleteDoc(doc(db, 'sorteos', sorteoId));
      toast.success('Sorteo eliminado correctamente');
    } catch (error) {
      console.error('Error al eliminar el sorteo:', error);
      toast.error('Error al eliminar el sorteo');
    }
  };

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/');
    toast.info('Sesión cerrada');
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const generateIframeCode = (sorteoId) => {
    const baseURL = window.location.origin;
    const iframeCode = `<iframe src="${baseURL}/sorteos/${sorteoId}" width="600" height="400"></iframe>`;
    navigator.clipboard.writeText(iframeCode).then(() => {
      toast.success('Iframe copiado al portapapeles');
    }, () => {
      toast.error('Error al copiar el iframe');
    });
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-64 bg-blue-800 text-white flex flex-col">
        <div className="p-4 font-bold text-2xl border-b border-blue-700">
          SorteosPanel
        </div>
        <nav className="flex-1 p-4">
          <ul>
            <li className="mb-2">
              <a href="#" className="block p-2 rounded hover:bg-blue-700">Dashboard</a>
            </li>
            <li className="mb-2">
              <a href="#" className="block p-2 rounded hover:bg-blue-700">Sorteos</a>
            </li>
            <li className="mb-2">
              <a href="#" className="block p-2 rounded hover:bg-blue-700">Configuración</a>
            </li>
          </ul>
        </nav>
        <div className="p-4 border-t border-blue-700">
          <button onClick={handleLogout} className="w-full bg-red-500 p-2 rounded hover:bg-red-600">
            Cerrar Sesión
          </button>
        </div>
      </div>

      <div className="flex-1 p-6">
        <h2 className="text-3xl mb-4">Panel de Sorteos</h2>
        
        <button
          onClick={openModal}
          className="p-2 bg-green-500 text-white rounded mb-4"
        >
          Crear Nuevo Sorteo
        </button>

        <CrearSorteoModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          onCreate={() => {
            setModalIsOpen(false);
          }}
        />

        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {sorteos.map((sorteo) => (
            <li key={sorteo.id} className="p-4 bg-white rounded shadow">
              <h3 className="text-xl font-bold mb-2">{sorteo.nombre}</h3>
              <p>{sorteo.descripcion}</p>
              <Link to={`/sorteos/${sorteo.id}`} className="text-blue-500 mt-2 block">Ver detalles</Link>
              <button
                onClick={() => handleEliminarSorteo(sorteo.id)}
                className="mt-2 p-2 bg-red-500 text-white rounded"
              >
                Eliminar Sorteo
              </button>
              <button
                onClick={() => generateIframeCode(sorteo.id)}
                className="mt-2 p-2 bg-blue-500 text-white rounded"
              >
                Generar Iframe
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SorteosPanel;
