import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { app } from '../firebase';

// Importa toast de react-toastify
import { toast } from 'react-toastify';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleRegister = async () => {
    const auth = getAuth(app);
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/panel'); // Redirigir al panel de sorteos
      toast.success('Registro exitoso!'); // Notificación de éxito
    } catch (error) {
      toast.error(`Error: ${error.message}`); // Notificación de error
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="p-6 bg-white rounded shadow-md w-full max-w-xs">
        <h2 className="text-2xl mb-4 text-center">Register</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-3 p-2 w-full border border-gray-300 rounded"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mb-3 p-2 w-full border border-gray-300 rounded"
        />
        <button onClick={handleRegister} className="w-full bg-blue-500 text-white p-2 rounded">
          Register
        </button>
      </div>
    </div>
  );
};

export default Register;
