import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase";
import Countdown from "./Countdown";
import "./Sorteo.css";
import { toast } from "react-toastify";
import { GoTrash } from "react-icons/go"; 
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; 

const Sorteo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [sorteo, setSorteo] = useState(null);
  const [boletos, setBoletos] = useState([]);
  const [seleccionados, setSeleccionados] = useState([]);
  const [usuario, setUsuario] = useState({
    nombre: "",
    apellido: "",
    telefono: "",
  });
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showPackageModal, setShowPackageModal] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalBoletos, setTotalBoletos] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [registeredPhones, setRegisteredPhones] = useState([]);
  const [videoPlaying, setVideoPlaying] = useState(false); 
  const pageSize = 50;
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const fetchSorteo = async () => {
      try {
        const sorteoDocRef = doc(db, "sorteos", id);
        const sorteoDoc = await getDoc(sorteoDocRef);
        if (sorteoDoc.exists()) {
          const sorteoData = sorteoDoc.data();
          if (sorteoData.fechaFin) {
            sorteoData.fechaFin = sorteoData.fechaFin.toDate();
          }
          setSorteo(sorteoData);
          setExpired(sorteoData.fechaFin ? new Date() > sorteoData.fechaFin : false);

          if (sorteoData.paquetes && sorteoData.paquetes.length > 0) {
            setShowPackageModal(true);
          }

          const registeredPhonesRef = await getDoc(sorteoDocRef);
          if (registeredPhonesRef.exists()) {
            const data = registeredPhonesRef.data();
            setRegisteredPhones(data.telefonosRegistrados || []);
          }

          const ticketsCollectionRef = collection(db, `sorteos/${id}/tickets`);
          const ticketsSnapshot = await getDocs(ticketsCollectionRef);
          const phones = ticketsSnapshot.docs.map((doc) => doc.data().telefono);
          setRegisteredPhones((prevPhones) => [...prevPhones, ...phones]);
        } else {
          console.log("No se encontró el documento.");
        }
      } catch (error) {
        console.error("Error al obtener el sorteo:", error);
      }
    };

    const fetchBoletosCount = async () => {
      try {
        const disponiblesRef = doc(db, `sorteos/${id}/boletos`, "disponibles");
        const disponiblesDoc = await getDoc(disponiblesRef);
        if (disponiblesDoc.exists()) {
          const disponiblesData = disponiblesDoc.data();
          setTotalBoletos(disponiblesData.numeros.length);
        }
      } catch (error) {
        console.error("Error al obtener el conteo de boletos:", error);
      }
    };

    fetchSorteo();
    fetchBoletosCount();
    fetchBoletos(0);
  }, [id]);

  const fetchBoletos = async (pageIndex) => {
    setLoading(true);
    try {
      const disponiblesRef = doc(db, `sorteos/${id}/boletos`, "disponibles");
      const apartadosRef = doc(db, `sorteos/${id}/boletos`, "apartados");
      const compradosRef = doc(db, `sorteos/${id}/boletos`, "comprados");

      const [disponiblesDoc, apartadosDoc, compradosDoc] = await Promise.all([
        getDoc(disponiblesRef),
        getDoc(apartadosRef),
        getDoc(compradosRef),
      ]);

      let disponibles = [];
      let apartados = [];
      let comprados = [];

      if (disponiblesDoc.exists()) {
        disponibles = disponiblesDoc.data().numeros.map((numero) => ({
          numero,
          estado: "disponible",
        }));
      }
      if (apartadosDoc.exists()) {
        apartados = apartadosDoc.data().numeros.map((numero) => ({
          numero,
          estado: "apartado",
        }));
      }
      if (compradosDoc.exists()) {
        comprados = compradosDoc.data().numeros.map((numero) => ({
          numero,
          estado: "comprado",
        }));
      }

      const start = pageIndex * pageSize;
      const boletosList = [...disponibles, ...apartados, ...comprados]
        .sort((a, b) => a.numero.localeCompare(b.numero))
        .slice(start, start + pageSize);

      setBoletos(boletosList);
    } catch (error) {
      console.error("Error al obtener boletos:", error);
    }
    setLoading(false);
  };

  const validatePhoneNumber = async (phoneNumber) => {
    if (sorteo?.esGratuito) {
      const isValid = !registeredPhones.includes(phoneNumber);
      setIsPhoneValid(isValid);
      return isValid;
    }
    setIsPhoneValid(true);
    return true;
  };

  const handlePhoneChange = async (e) => {
    const phone = e.target.value.replace(/\D/g, "").slice(0, 10); 
    setUsuario((prevState) => ({ ...prevState, telefono: phone }));

    if (phone.length === 10) {
      await validatePhoneNumber(phone);
    }
  };

  const handleSelectBoleto = (boleto) => {
    if (boleto.estado === "disponible") {
      const limite = selectedPackage || sorteo.limitePorPersona;

      if (limite && seleccionados.length >= limite) {
        toast.warn(`Solo puedes seleccionar hasta ${limite} números.`);
        return;
      }

      setSeleccionados([...seleccionados, boleto.numero]);
      setBoletos(
        boletos.map((b) =>
          b.numero === boleto.numero ? { ...b, estado: "seleccionado" } : b
        )
      );
    }
  };

  const handleComprarBoletos = async () => {
    try {
      if (!usuario.nombre || !usuario.apellido || !usuario.telefono) {
        toast.warn("Por favor, ingrese su nombre, apellido y teléfono.");
        return;
      }

      if (sorteo?.esGratuito && !isPhoneValid) {
        toast.warn(
          "Este número de teléfono ya ha registrado un boleto en este sorteo gratuito."
        );
        return;
      }

      const data = {
        nombre: usuario.nombre,
        apellido: usuario.apellido,
        telefono: usuario.telefono,
        boletos: seleccionados,
        sorteo: sorteo.nombre,
        total: seleccionados.length * sorteo.costo
      };

      // Redirigir según el tipo de sorteo
      if (sorteo.redirectToExternalPage && sorteo.externalPageUrl) {
        const queryParams = new URLSearchParams(data).toString();
        window.location.href = `${sorteo.externalPageUrl}?${queryParams}`;
      } else {
        toast.success("Boletos registrados exitosamente.");
        navigate("/gracias"); // Redirige al componente de gracias
      }

    } catch (error) {
      console.error("Error al preparar la compra de boletos:", error);
      toast.error("Error al preparar la compra de boletos");
    }
  };

  const handleRemoveSeleccionado = (boleto) => {
    setSeleccionados(seleccionados.filter((b) => b !== boleto));
    setBoletos(
      boletos.map((b) =>
        b.numero === boleto ? { ...b, estado: "disponible" } : b
      )
    );
  };

  const handleNextPage = () => {
    const newIndex = pageIndex + 1;
    setPageIndex(newIndex);
    fetchBoletos(newIndex);
  };

  const handlePreviousPage = () => {
    if (pageIndex > 0) {
      const newIndex = pageIndex - 1;
      setPageIndex(newIndex);
      fetchBoletos(newIndex);
    }
  };

  const closePackageModal = () => {
    setShowPackageModal(false);
  };

  const selectPackageAndClose = (packSize) => {
    setSelectedPackage(packSize);
    closePackageModal();
  };

  const handlePlayVideo = () => {
    setVideoPlaying(true);
  };

  return (
    <div className="sorteo-container p-4 bg-black text-white min-h-screen flex flex-col items-center">
      {sorteo ? (
        <>
          {sorteo.mostrarTitulo && (
            <h1 className="text-4xl font-bold mb-4">{sorteo.nombre}</h1>
          )}
          {sorteo.mostrarDescripcion && (
            <p className="text-lg mb-4">{sorteo.descripcion}</p>
          )}
          {!videoPlaying && sorteo.mediaType === 'video' && sorteo.mediaLink ? (
            <div className="relative mb-4 w-full h-auto object-cover" style={{ maxHeight: "620px" }}>
              <img
                src={sorteo.posterLink || sorteo.mediaLink}
                alt="Portada del video"
                className="w-full h-auto object-cover cursor-pointer"
                onClick={handlePlayVideo}
              />
              <div
                className="absolute inset-0 flex items-center justify-center"
                onClick={handlePlayVideo}
              >
                <button className="bg-black bg-opacity-50 text-white text-6xl p-4 rounded-full">
                  ▶️
                </button>
              </div>
            </div>
          ) : (
            sorteo.mediaLink && 
            <video
              src={sorteo.mediaLink}
              controls
              className="mb-4 w-full h-auto object-cover"
              style={{ maxHeight: "620px" }}
              autoPlay
            />
          )}
          {expired ? (
            <div className="overlay">
              <p className="overlay-text">Este sorteo ya ha finalizado.</p>
            </div>
          ) : (
            <>
              {sorteo.fechaFin && (
                <>
                  <p className="text-lg font-bold mt-1">
                    Este sorteo termina en:
                  </p>
                  <Countdown targetDate={sorteo.fechaFin} />
                </>
              )}
              <div className="bg-gray-800 p-4 rounded-lg shadow-lg w-full mt-6">
                <p className="text-lg font-bold mt-1">
                  Registra tus boletos
                </p>
                <div className="mb-4 form-row">
                  <div className="form-group">
                    <label className="form-label"></label>
                    <input
                      type="text"
                      placeholder="Nombre"
                      value={usuario.nombre}
                      onChange={(e) => setUsuario({ ...usuario, nombre: e.target.value })}
                      className="p-2 border border-gray-600 rounded mb-2 w-full form-input"
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label"></label>
                    <input
                      type="text"
                      placeholder="Apellido"
                      value={usuario.apellido}
                      onChange={(e) => setUsuario({ ...usuario, apellido: e.target.value })}
                      className="p-2 border border-gray-600 rounded mb-2 w-full form-input"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label className="form-label"></label>
                  <input
                    type="text"
                    placeholder="Teléfono"
                    value={usuario.telefono}
                    onChange={handlePhoneChange}
                    className={`p-2 border ${
                      isPhoneValid ? "border-gray-600" : "border-red-500"
                    } rounded mb-2 w-full`}
                    maxLength={10}
                  />
                  {!isPhoneValid && (
                    <p className="text-red-500 text-sm">
                      Este número ya ha registrado un boleto.
                    </p>
                  )}
                </div>

                {seleccionados.length > 0 && (
                  <div className="mt-4">
                    <button
                      onClick={handleComprarBoletos}
                      className="p-2 bg-green-500 text-white rounded w-full"
                      disabled={sorteo.isFree && !isPhoneValid}
                    >
                      Comprar Boletos
                    </button>
                  </div>
                )}
                <p className="text-lg font-bold mt-4">
                  Total: ${seleccionados.length * sorteo.costo}
                </p>

                <div className="flex flex-wrap mb-4 justify-center">
                  {seleccionados.map((boleto) => (
                    <div
                      key={boleto}
                      className="m-1 flex items-center bg-green-600 p-2 rounded text-white"
                    >
                      <span className="px-2 py-1">{boleto}</span>
                      <button
                        onClick={() => handleRemoveSeleccionado(boleto)}
                        className="ml-2 text-white"
                      >
                        <GoTrash />
                      </button>
                    </div>
                  ))}
                </div>
                <div className="grid-container-wrapper">
                  <button
                    onClick={handlePreviousPage}
                    className="navigation-arrow left-arrow"
                    disabled={pageIndex === 0}
                  >
                    <FaChevronLeft />
                  </button>
                  <div className="grid-container mb-4">
                    {boletos.map((boleto) => (
                      <button
                        key={boleto.numero}
                        className={`p-2 rounded ${
                          boleto.estado !== "disponible"
                            ? "bg-gray-500 cursor-not-allowed"
                            : "bg-blue-600 text-white cursor-pointer"
                        }`}
                        onClick={() => handleSelectBoleto(boleto)}
                        disabled={boleto.estado !== "disponible"}
                      >
                        {boleto.numero}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={handleNextPage}
                    className="navigation-arrow right-arrow"
                    disabled={boletos.length < pageSize}
                  >
                    <FaChevronRight />
                  </button>
                </div>
                {loading && <p>Cargando...</p>}
              </div>
            </>
          )}
        </>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export default Sorteo;
