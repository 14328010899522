import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-4">Bienvenido a Sorteos</h1>
      <p className="text-xl mb-4">Participa en nuestros sorteos y gana premios increíbles.</p>
      <Link to="/plataforma" className="p-2 bg-blue-500 text-white rounded">
        Iniciar sesión
      </Link>
    </div>
  );
};

export default Home;
