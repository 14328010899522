import React, { useState, useEffect } from 'react';
import './Countdown.css'; // Asegúrate de usar un archivo CSS específico para el componente Countdown

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        dias: Math.floor(difference / (1000 * 60 * 60 * 24)),
        horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutos: Math.floor((difference / 1000 / 60) % 60),
        segundos: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        dias: 0,
        horas: 0,
        minutos: 0,
        segundos: 0,
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
   
    <div className="countdown">
     
      <div className="countdown-segment">
        <div className="circle">
          <span className="countdown-time">{String(timeLeft.dias).padStart(2, '0')}</span>
        </div>
        <span className="countdown-label">DÍAS</span>
      </div>
      <div className="countdown-segment">
        <div className="circle">
          <span className="countdown-time">{String(timeLeft.horas).padStart(2, '0')}</span>
        </div>
        <span className="countdown-label">HORAS</span>
      </div>
      <div className="countdown-segment">
        <div className="circle">
          <span className="countdown-time">{String(timeLeft.minutos).padStart(2, '0')}</span>
        </div>
        <span className="countdown-label">MINUTOS</span>
      </div>
      <div className="countdown-segment">
        <div className="circle">
          <span className="countdown-time">{String(timeLeft.segundos).padStart(2, '0')}</span>
        </div>
        <span className="countdown-label">SEGUNDOS</span>
      </div>
    </div>
  );
};

export default Countdown;
